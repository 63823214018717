import React, { Component } from 'react'
import {
  Form,
  FormGroup,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

class StatusDropdown extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownOpen: false
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  render () {
    const statusOptions = ['new', 'in progress', 'failed', 'done', 'recovered']
    const dropdownItems = statusOptions.map(option => {
      return (
        <DropdownItem
          key={option}
          onClick={() => this.props.handleChange({ productionStatus: option })}>
          {option}
        </DropdownItem>
      )
    })

    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}>
        <DropdownToggle caret>
          {this.props.status || 'Status'}
        </DropdownToggle>
        <DropdownMenu>
          {dropdownItems}
          <DropdownItem divider />
          <DropdownItem onClick={() => this.props.handleChange({ productionStatus: '' })}>
            reset
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default function Filters (props) {
  return (
    <Form inline onSubmit={props.onSubmit}>
      <FormGroup className='mr-2'>
        <InputGroup>
          <InputGroupAddon addonType='prepend'>
                    From Date
          </InputGroupAddon>
          <Input
            type='date'
            value={props.fromDate}
            placeholder='From Date'
            onChange={e => props.handleChange({ fromDate: e.target.value })} />
        </InputGroup>
      </FormGroup>
      <FormGroup className='mr-2'>
        <InputGroup>
          <InputGroupAddon addonType='prepend'>
                    To Date
          </InputGroupAddon>
          <Input
            type='date'
            value={props.toDate}
            placeholder='From Date'
            onChange={e => props.handleChange({ toDate: e.target.value })} />
        </InputGroup>
      </FormGroup>
      <FormGroup className='mr-2'>
        <InputGroup>
          <StatusDropdown handleChange={props.handleChange} status={props.productionStatus} />
        </InputGroup>
      </FormGroup>
      <Button type='submit'>Filter</Button>
    </Form>
  )
}
