import fetchWrapper from '../utils/FetchWrapper'

export function getUser () {
  return fetchWrapper(`/v0/user`)
}

export function authenticate (username, password) {
  return fetchWrapper(`authenticate`, {
    body: { username, password },
    method: 'POST'
  })
}
