import React, { Component } from 'react'
import { Alert } from 'reactstrap'

export default class Message extends Component {
  componentDidUpdate (prevProps) {
    ['errorMessage', 'successMessage'].forEach(messageType => {
      if (this.props[messageType] && !prevProps[messageType]) {
        setTimeout(() => { this.props.clearMessage(messageType) }, 3000)
      }
    })
  }

  render () {
    const { errorMessage, successMessage } = this.props
    if (!errorMessage && !successMessage) return <div />

    const color = errorMessage ? 'danger' : 'success'
    const message = errorMessage || successMessage

    return (
      <Alert className='sticky-top text-center' color={color}>
        {message}
      </Alert>
    )
  }
}
