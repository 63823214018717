import React, { Component } from 'react'
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Input
} from 'reactstrap'
import Filters from './Filters'

function Search (props) {
  return (
    <Form inline onSubmit={props.onSubmit}>
      <FormGroup className='mr-2'>
        <Input
          type='text'
          value={props.productKey}
          onChange={e => props.handleChange({ productKey: e.target.value })}
          placeholder='Product Key' />
      </FormGroup>
      <Button type='submit'>Search</Button>
    </Form>
  )
}

export default class Controls extends Component {
  render () {
    return (
      <React.Fragment>
        <Row className='mt-3 mb-3'>
          <Col xs='auto'>
            <Search
              handleChange={this.props.handleChange}
              onSubmit={this.props.fetchDevices}
              productKey={this.props.filters.productKey} />
          </Col>
          <Col xs='auto'>
            <Filters
              handleChange={this.props.handleChange}
              onSubmit={this.props.fetchDevices}
              {...this.props.filters}
            />
          </Col>
        </Row>
        <Row className='mt-3 mb-3 pr'>
          <Col xs='auto'>
            <Button onClick={this.props.fetchDevices}>Refresh</Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
