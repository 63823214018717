import fetchWrapper from '../utils/FetchWrapper'
import humps from 'humps'

export function fetchDevices (filters) {
  const query = Object.keys(filters).reduce((queryArray, currKey) => {
    let filterVal = filters[currKey]
    if (filterVal) {
      const formattedVal = filterVal === 'in progress' ? 'in_progress' : filterVal
      return queryArray.concat([`${humps.decamelize(currKey)}=${formattedVal}`])
    } else {
      return queryArray
    }
  }, []).join('&')
  return fetchWrapper(`devices?${query}`)
}

export function fixDevice (id) {
  return fetchWrapper(`devices/${id}/fix`, {
    method: 'PUT'
  })
}

export function updateDevice (id) {
  return fetchWrapper(`devices/${id}/update_frame`, {
    method: 'PUT'
  })
}
