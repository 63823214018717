import React from 'react'
import { Container } from 'reactstrap'
import { Provider } from 'react-redux'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import configureStore from '../store/Store'
import Nav from './Nav'
import PrimaryLayout from './PrimaryLayout'
import Auth from './Auth'
import AuthorizedRoute from './AuthorizedRoute'

const store = configureStore()

export default function App () {
  return (
    <Provider store={store}>
      <Container>
        <Nav />
        <HashRouter>
          <Switch>
            <Route path='/auth' component={Auth} />
            <AuthorizedRoute path='/' component={PrimaryLayout} />
            <Redirect to='/' />
          </Switch>
        </HashRouter>
      </Container>
    </Provider>
  )
}
