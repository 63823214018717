import 'whatwg-fetch'

const API_ORIGIN = process.env.API_ORIGIN

export default function fetchWrapper (url, data = {}) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('userToken')
    const headers = new Headers({ 'Content-Type': 'application/json' })
    if (token) headers.append('Authorization', `Token ${token}`)

    const method = data.method || 'GET'
    const mode = 'cors'
    let fullUrl
    if (url.startsWith('/')) {
      fullUrl = `${API_ORIGIN}${url}`
    } else {
      fullUrl = `${API_ORIGIN}/production/${url}`
    }

    return fetch(fullUrl, { 
      headers, method, mode, body: JSON.stringify(data.body)
    }).then(response => {
      if (response.status >= 400) {
        response.json().then(parsed => reject(parsed))
      } else {
        resolve(response.json())
      }
    })
  })
}
