import * as constants from '../constants/AuthConstants'

const _nullAuth = { 
  loading: true,
  loggedIn: false,
  error: null,
  user: {}
}

export default function AuthReducer (state = _nullAuth, action) {
  switch (action.type) {
    case constants.RECEIVE_USER: {
      return { user: action.payload.data, loggedIn: true, loading: false }
    }
    case constants.LOGOUT: {
      return Object.assign(_nullAuth, { loading: false })
    }
    case constants.RECEIVE_AUTH_ERROR: {
      return Object.assign({}, state, { loading: false, error: action.error })
    }
    default: {
      return state
    }
  }
}
