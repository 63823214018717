import React from 'react'
import { Card, Row, Col } from 'reactstrap'

export default function Overview (props) {
  return (
    <Card body>
      <Row className='text-center'>
        <Col>
          <span>New: {props.new}</span>
        </Col>
        <Col>
          <span>In Progress: {props.inProgress}</span>
        </Col>
        <Col>
          <span>Failed: {props.failed}</span>
        </Col>
        <Col>
          <span>Done: {props.done}</span>
        </Col>
        <Col>
          <span>Recovered: {props.recovered}</span>
        </Col>
      </Row>
    </Card>
  )
}
