import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logoutUser } from '../actions/AuthActions'

function mapStateToProps (state) {
  return {}
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ logoutUser }, dispatch)
}

function LogoutButton (props) {
  return <span onClick={props.logoutUser}>Logout</span>
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutButton)
