import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'

import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'


document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('root')
  ReactDOM.render(<App />, rootNode)
})
