import React, { Component } from 'react'
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card
} from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loginUserWithAuth } from '../actions/AuthActions'

function mapStateToProps (state) {
  return { loggedIn: state.auth.loggedIn }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ loginUserWithAuth }, dispatch)
}

class Auth extends Component {
  constructor (props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      loading: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.componentWillMount = this.componentWillMount.bind(this)
  }

  componentWillMount () {
    if (this.props.loggedIn) this.props.history.push('/')
  }

  handleChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ loading: true }, () => {
      this.props.loginUserWithAuth(this.state)
        .then(() => this.props.history.push('/'))
        .catch(() => this.setState({ loading: false }))
    })
  }

  render () {
    return (
      <Row>
        <Col className='mt-3'>
          <Card body>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label for="email">Email address</Label>
                <Input type="text"
                  id="email"
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for="password">Password</Label>
                <Input type="password"
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange} />
              </FormGroup>

              <Button 
                type="submit"
                disabled={this.state.loading}>
                Submit
              </Button>

              <FormGroup className='mt-3'>
                <span>{this.state.loading ? 'Loading...' : ''}</span>
              </FormGroup>
            </Form>
          </Card>
        </Col>
        <Col />
        <Col />
      </Row>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
