import * as constants from '../constants/AuthConstants'
import * as API from '../api/Auth'

export const loginUserWithToken = () => dispatch => {
  return API.getUser().then(payload => dispatch({
    type: constants.RECEIVE_USER, payload
  })).catch(error => {
    localStorage.removeItem('userToken')
    dispatch({ type: constants.RECEIVE_AUTH_ERROR, error })
  })
}

export const loginUserWithAuth = ({username, password}) => dispatch => {
  return API.authenticate(username, password).then(payload => {
    localStorage.setItem('userToken', payload.token)
    return dispatch(loginUserWithToken())
  }).catch(error => {
    localStorage.removeItem('userToken')
    dispatch({ type: constants.RECEIVE_AUTH_ERROR, error })
  })
}

export const logoutUser = () => dispatch => {
  localStorage.removeItem('userToken')
  return dispatch({ type: constants.LOGOUT })
}
