import React, { Component } from 'react'
import moment from 'moment'
import { Table, Button } from 'reactstrap'

function DeviceRow (props) {
  const { id, productKey, productionStatus, productionStatusDate, newVersionId } = props
  const fixDisabled = productionStatus !== 'failed'
  const updateDisabled = !newVersionId ||
    (productionStatus !== 'done' && productionStatus !== 'recovered')
  const readableDate = productionStatusDate
    ? moment(productionStatusDate).format('MM-DD-YY HH:mm:ss')
    : ''
  const readableStatus = productionStatus.replace('_', ' ')

  return (
    <tr>
      <th scope='row'>{id}</th>
      <td>{productKey}</td>
      <td>{readableStatus}</td>
      <td>{readableDate}</td>
      <td>
        <Button
          outline={fixDisabled}
          disabled={fixDisabled}
          onClick={() => {
            !fixDisabled && props.fix(id)
          }}>
          Fix
        </Button>
        </td>
        <td>
        <Button
          outline={updateDisabled}
          disabled={updateDisabled}
          onClick={() => {
            !updateDisabled && props.update(id)
          }}>
          Update
        </Button>
      </td>
    </tr>
  )
}

export default class DeviceTable extends Component {
  render () {
    return (
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Product Key</th>
            <th>Status</th>
            <th>Time</th>
            <th>Fix</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody>
          {this.props.devices.map(device => {
            return (
              <DeviceRow
                key={device.id}
                update={this.props.updateDevice}
                fix={this.props.fixDevice}
                {...device} />
            )
          })}
        </tbody>
      </Table>
    )
  }
}
