import React from 'react'
import { connect } from 'react-redux'
import {
  Navbar,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse
} from 'reactstrap'
import LogoutButton from './LogoutButton'

function mapStateToProps (state) {
  return { email: state.auth.user.email }
}

function ProdNav (props) {
  return (
    <Navbar color='light' light expand='md'>
      <NavbarBrand href='/'>Meural Production</NavbarBrand>
      <Collapse navbar>
        <Nav className='ml-auto' navbar>
          {
            props.email && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {props.email}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <LogoutButton />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default connect(mapStateToProps)(ProdNav)
